import {useMutation, useQuery, type UseQueryOptions} from '@tanstack/react-query'
import {type EmptyObject} from 'react-hook-form'
import {type SimpleAPI} from '~/mercuryWebBackend/SimpleAPI'

export function useSimpleQuery<
  Response,
  Params = EmptyObject,
  PathPieces = EmptyObject,
>(
  endpoint: SimpleAPI<Response, Params, PathPieces>,
  params: Params,
  pathPieces: PathPieces,
  extras?: Omit<UseQueryOptions<Response>, 'queryKey'>
) {
  const queryKey = [endpoint.method, endpoint.getPath(pathPieces), params]
  return useQuery({
    queryKey,
    async queryFn() {
      return await endpoint.fetch({
        params,
        pathPieces,
      })
    },
    ...extras,
  })
}

export function useSimpleMutation<
  Response,
  Params = EmptyObject,
  PathPieces = EmptyObject,
>(endpoint: SimpleAPI<Response, Params, PathPieces>) {
  return useMutation({
    async mutationFn({
      params,
      pathPieces,
    }: {
      params: Params
      pathPieces: PathPieces
    }) {
      return await endpoint.fetch({
        params,
        pathPieces,
      })
    },
  })
}
