import {Method, SimpleAPI} from '../../SimpleAPI'

type InterestInfo = {
  currentMaximumApyBasisPoints: number
  currentMaximumInterestBasisPoints: number
  effectiveDate: string
}

type InterestInfoForOrgKind = {
  checkingAccountKind: InterestInfo
  savingsAccountKind: InterestInfo
}

type ListInterestRatesResponse = {
  business: InterestInfoForOrgKind
  personal: InterestInfoForOrgKind
}

export class ListInterestRates extends SimpleAPI<ListInterestRatesResponse, any> {
  revalidate = 60 * 60 * 24 // revalidate every 24 hours
  method = Method.POST

  getPath() {
    return '/interest-rates'
  }

  protected async getMockResponse(): Promise<ListInterestRatesResponse> {
    return {
      business: {
        checkingAccountKind: {
          currentMaximumApyBasisPoints: 0,
          currentMaximumInterestBasisPoints: 0,
          effectiveDate: '2024-04-16',
        },
        savingsAccountKind: {
          currentMaximumApyBasisPoints: 0.1,
          currentMaximumInterestBasisPoints: 0.1,
          effectiveDate: '2024-04-16',
        },
      },
      personal: {
        checkingAccountKind: {
          currentMaximumApyBasisPoints: 0.1,
          currentMaximumInterestBasisPoints: 0.1,
          effectiveDate: '2024-04-16',
        },
        savingsAccountKind: {
          currentMaximumApyBasisPoints: 500,
          currentMaximumInterestBasisPoints: 489,
          effectiveDate: '2024-04-01',
        },
      },
    }
  }
}
