// @ts-check
import {createEnv} from '@t3-oss/env-nextjs'
import {z} from 'zod'

/**
 * NB: in `mercury-web`, these urls are determined by environment. Since we are now splitting up the
 * app and FEM experiences, some of the per-environment configuration no longer applies, so it's
 * currently being excluded (may change this as we get further along in our migration?).
 *
 * However, now that the app and FEM experiences will be on 2 different subdomains, we need to make
 * sure links are using the appropriate domain for their respective experience.
 */
export const mercuryDomain = 'mercury.com'
export const mercuryAppDomain = `app.${mercuryDomain}`
export const mercuryProxyDomain = `proxyinternal.${mercuryDomain}`

/**
 * WARNING FROM THE LIBRARY:
 *
 * While defining both the client and server schemas in a single file provides the best developer experience,
 * it also means that your validation schemas for the server variables will be shipped to the client.
 * If you consider the names of your variables sensitive, you should split your schemas into two files.
 *
 * ---
 *
 * I don't think this is the case for us, but if it is in the future, we should split this configuration.
 *
 */

export const backendSource = z.enum(['mock', 'localhost', 'production', 'staging'])

// https://env.t3.gg/docs/recipes
const parsedBoolean = z
  .string()
  // only allow "true" or "false"
  .refine(s => s === 'true' || s === 'false')
  // transform to boolean
  .transform(s => s === 'true')

const optionalParsedBoolean = parsedBoolean.optional()

export const env = createEnv({
  server: {
    DATOCMS_API_TOKEN: z.string(),
    MWB_URL: z.string(),
    UPLOAD_BUGSNAG_SOURCEMAPS: parsedBoolean.default('false'),
    IS_DOCKER: parsedBoolean.default('false'),
    DRAFT_TOKEN: z.string().optional(), // defined at runtime only
    REVALIDATE_TOKEN: z.string().optional(), // defined at runtime only
    REDIS_URL: z.string().url().optional(), // defined at runtime only
    SLACK_DATO_CMS_NOTIFICATIONS_WEBHOOK: z.string().optional(), // defined at runtime only
  },
  client: {
    NEXT_PUBLIC_BACKEND_SOURCE: backendSource,
    NEXT_PUBLIC_BUGSNAG_API_KEY: z.string(),
    NEXT_PUBLIC_BUGSNAG_NO_REPORT: parsedBoolean,
    NEXT_PUBLIC_ENABLE_DEVLOG: optionalParsedBoolean,
    NEXT_PUBLIC_MERCURY_WEB_SANITY_PROJECT_ID: z.string(),
    NEXT_PUBLIC_DATOCMS_PROJECT_ID: z.string(),
    NEXT_PUBLIC_NODE_ENV: z
      .enum(['development', 'production'])
      .optional()
      .default('development'),
    NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE: z
      .enum(['development', 'production', 'mock', 'staging'])
      .optional()
      .default('development'),
    NEXT_PUBLIC_SEGMENT_KEY: z.string().optional(),
    NEXT_PUBLIC_DATOCMS_ENVIRONMENT: z.string().optional(),
    NEXT_PUBLIC_IFRAME_RESIZER_LICENSE_KEY: z.string(),
  },
  experimental__runtimeEnv: {
    // For Next.js >= 13.4.4, you only need to destructure client variables:
    NEXT_PUBLIC_BACKEND_SOURCE: process.env.NEXT_PUBLIC_BACKEND_SOURCE,
    NEXT_PUBLIC_BUGSNAG_API_KEY: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    NEXT_PUBLIC_BUGSNAG_NO_REPORT: process.env.NEXT_PUBLIC_BUGSNAG_NO_REPORT,
    NEXT_PUBLIC_ENABLE_DEVLOG: process.env.NEXT_PUBLIC_ENABLE_DEVLOG,
    NEXT_PUBLIC_MERCURY_WEB_SANITY_PROJECT_ID:
      process.env.NEXT_PUBLIC_MERCURY_WEB_SANITY_PROJECT_ID,
    NEXT_PUBLIC_DATOCMS_PROJECT_ID: process.env.NEXT_PUBLIC_DATOCMS_PROJECT_ID,
    NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE: process.env.NEXT_PUBLIC_BUGSNAG_RELEASE_STAGE,
    NEXT_PUBLIC_SEGMENT_KEY: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    NEXT_PUBLIC_DATOCMS_ENVIRONMENT: process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT,
    NEXT_PUBLIC_IFRAME_RESIZER_LICENSE_KEY:
      process.env.NEXT_PUBLIC_IFRAME_RESIZER_LICENSE_KEY,
  },
  skipValidation: process.env.SKIP_ENV_VALIDATION === 'true',
  emptyStringAsUndefined: true,
})
