// This prefix string must remain manually sync'd with the same constant on the backend.
// (Customer referrals are a hack on top of partner referrals, and they are unfortunately
// distinguished in this stringy way.)
//
// Ideally, the differentiator would be sending the organization id of the customer referrer
// to the backend, and the backend would look up the partner from the org id.
export const customerReferralPartnerCallsign = (callsign: string) =>
  `${customerReferralPrefix}${callsign}`

const customerReferralPrefix = 'customer-referral-'
