import {type FemReferralAttributionParams} from '~/mercuryWebBackend/endpoints/FEMReferralAttribution'
import Cookies from 'js-cookie'
import {FACEBOOK_PIXEL_COOKIE_NAME, GA_COOKIE_NAME} from '~/config/cookies'
import {customerReferralPartnerCallsign} from '~/utils/customerReferralPartnerCallsign'

/**
 * Handles scenarios where typescript rests on the expectation of a string being provided,
 * but the incoming value is not guaranteed to be as such
 */
export const cleanupPossibleStringArray = (val: unknown): string | undefined => {
  if (typeof val === 'string') {
    return val
  } else if (val instanceof Array && typeof val[0] === 'string') {
    return val[0]
  } else {
    return undefined
  }
}

const getGaClientIdFromCookies = (): string | undefined => {
  const gaCookie = Cookies.get(GA_COOKIE_NAME)
  if (!gaCookie) {
    return gaCookie
  }

  return gaCookie.split('.').slice(-2).join('.')
}

function getReferrerUrl() {
  let referrerUrl: string | undefined
  if (document.referrer === '') {
    referrerUrl = undefined
  } else {
    try {
      const parsedReferrer = new URL(document.referrer)
      referrerUrl =
        parsedReferrer.host.replace(/^www./, '') + parsedReferrer.pathname
    } catch (e) {
      referrerUrl = document.referrer
    }
  }
  return referrerUrl
}

function getPartnerCallsign() {
  const path = window.location.pathname
  const pathParts = path.split('/')
  if (pathParts.includes('partner')) {
    const partnerIndex = pathParts.indexOf('partner')
    return pathParts[partnerIndex + 1]
  }

  if (pathParts.includes('r')) {
    const rIndex = pathParts.indexOf('r')
    return customerReferralPartnerCallsign(pathParts[rIndex + 1])
  }
}

export function collectSignupAnalytics(): FemReferralAttributionParams {
  // Since moving to nextjs, we sometimes fail to record utm parameters.
  // The parameters are present in fem_referral_attributions.landing_page
  // but the corresponding param columns are null, e.g. fem_referral_attributions.campaign.
  // It's interesting that we get the params and the landing page from two different sources:
  // the params are provided by the `useSearchParams` hook and the landing page
  // comes directly from the `window` object.
  // Since I am unable to reproduce this issue, I want to see if getting
  // everything from the same source, the window object, makes a difference.
  const searchParams = new URLSearchParams(window.location.search).entries()
  const qs: Record<string, string> = {}
  for (const [key, value] of searchParams) {
    const cleanValue = cleanupPossibleStringArray(value)
    if (cleanValue) {
      qs[key] = cleanValue
    }
  }

  const signupAnalytics: FemReferralAttributionParams = {
    gaClientId: getGaClientIdFromCookies(),
    facebookPixelId: Cookies.get(FACEBOOK_PIXEL_COOKIE_NAME),
    landingPage: window.location.href,
    referralPartnerCallsign: getPartnerCallsign(),
  }

  if (Object.keys(qs).length > 0) {
    if (qs.utm_campaign !== null && qs.utm_campaign !== undefined) {
      signupAnalytics.campaign = qs.utm_campaign
    }

    if (qs.utm_source !== null && qs.utm_source !== undefined) {
      signupAnalytics.source = qs.utm_source
    }

    if (qs.utm_medium !== null && qs.utm_medium !== undefined) {
      signupAnalytics.medium = qs.utm_medium
    }

    if (qs.utm_term !== null && qs.utm_term !== undefined) {
      signupAnalytics.term = qs.utm_term
    }

    if (qs.utm_content !== null && qs.utm_content !== undefined) {
      signupAnalytics.content = qs.utm_content
    }

    if (qs.gclid) {
      signupAnalytics.gclid = qs.gclid
    }

    if (qs.msclkid) {
      signupAnalytics.msclkid = qs.msclkid
    }

    if (qs.twclid) {
      signupAnalytics.twclid = qs.twclid
    }

    if (qs.irclickid) {
      signupAnalytics.irclickid = qs.irclickid
    }

    if (qs.fbclid) {
      const fbclidDate = new Date()
      const fbclid = `fb.1.${fbclidDate.getTime()}.${qs.fbclid}`
      signupAnalytics.facebookClickId = fbclid
    }

    if (qs.fdrclid) {
      signupAnalytics.funderaClickId = qs.fdrclid
    }

    if (qs.utm_source === 'quicksprout' && qs.subid) {
      signupAnalytics.quicksproutClickId = qs.subid
    }

    // NOTE: Skipping `hasAlphaCode` and `ma` (manual attribution) as these only happen on the signup page
  }

  const referrerUrl = getReferrerUrl()
  if (referrerUrl) {
    signupAnalytics.referrerUrl = referrerUrl
  }

  return signupAnalytics
}
