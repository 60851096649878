'use client'

import IframeResizer from '@iframe-resizer/react'
import {env} from '~/config/env.mjs'

type IframeProps = {
  src: string
}

const Iframe = ({src}: IframeProps) => {
  return (
    <div className="h-auto">
      <IframeResizer
        license={env.NEXT_PUBLIC_IFRAME_RESIZER_LICENSE_KEY}
        src={src}
        style={{width: '100%', height: '100%'}}
      />
    </div>
  )
}

export default Iframe
