import {Slot} from '@radix-ui/react-slot'
import {type PropsWithChildren} from 'react'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {type ThemeSwitcherFragment} from '../DatoThemeSwitcher/_generated/fragments.graphql'

type DatoThemeSelectorProps = {
  theme: ThemeSwitcherFragment
} & PropsWithChildren

export function DatoThemeSelector(props: DatoThemeSelectorProps) {
  const {children, theme} = props

  const themeName =
    theme.name in themeToClassName ? (theme.name as Theme) : 'neutral'

  return <Slot className={themeToClassName[themeName]}>{children}</Slot>
}
