import {Method, SimpleAPI} from '../../SimpleAPI'

export type FemReferralAttributionData = {
  femAnonymousUserId?: string
  referralPartnerId?: string
  campaign?: string
  source?: string
  medium?: string
  term?: string
  content?: string
  referrerUrl?: string
  gclid?: string
  gaClientId?: string
  msclkid?: string
  twclid?: string
  irclickid?: string
  facebookPixelId?: string
  facebookClickId?: string
  funderaClickId?: string
  quicksproutClickId?: string
  landingPage?: string
}

export type FemReferralAttributionParams = {
  /**
   * DEPRECATED. Use `referralPartnerCallsign instead.
   */
  referralPartner?: string
  referralPartnerCallsign?: string
  campaign?: string
  source?: string
  medium?: string
  term?: string
  content?: string
  referrerUrl?: string
  gclid?: string
  gaClientId?: string
  msclkid?: string
  twclid?: string
  irclickid?: string
  facebookPixelId?: string
  facebookClickId?: string
  funderaClickId?: string
  quicksproutClickId?: string
  landingPage?: string
}

export type FemReferralAttributionResponse =
  | ReferralAttributionSkippedForSignedInUser
  | ReferralAttributionData

type ReferralAttributionSkippedForSignedInUser = {
  tag: 'skippedForSignedInUser'
}

type ReferralAttributionData = {
  tag: 'data'
  contents: FemReferralAttributionData
}

export class FEMReferralAttribution extends SimpleAPI<
  FemReferralAttributionResponse,
  FemReferralAttributionParams
> {
  revalidate = 'client-only' as const
  method = Method.POST

  getPath() {
    return '/referral-attributions'
  }

  protected preProcessPostParams(
    params: FemReferralAttributionParams
  ): FemReferralAttributionParams {
    return {
      ...params,
      // TODO(CP-215): Referrer partner stuff. This is currently sent via the proxied pages in mercury-web
      // referralPartner: safely(t.referrerData, r =>
      //   fromFrontendKey(apiReferralPartnerFromReferrerData(r))
      // ),
    }
  }

  protected async getMockResponse() {
    return {
      tag: 'skippedForSignedInUser' as const,
    }
  }
}
