'use client'

import {
  type ButtonEvent,
  type ClickEvent,
  type CopyEvent,
  type TooltipEvent,
} from './types'

import {AnalyticsBrowser, type UserTraits} from '@segment/analytics-next'
import {debounce} from 'lodash'
import {env} from '~/config/env.mjs'

export const devLog = env.NEXT_PUBLIC_ENABLE_DEVLOG ? console.log : () => {}

export const _analytics = new AnalyticsBrowser()

export const Analytics = {
  page(properties: Record<string, unknown>) {
    Analytics._page(properties)
  },

  trackButton(event: ButtonEvent) {
    Analytics._track(`${event}-button`)
  },

  trackClick(event: ClickEvent) {
    if (typeof event === 'string') {
      Analytics._track('click', {type: event})
      return
    }
    Analytics._track('click', event)
  },

  trackCopy(event: CopyEvent) {
    if (typeof event === 'string') {
      Analytics._track('copy', {type: event})
      return
    }
    Analytics._track('copy', event)
  },

  trackTooltip: debounce((event: TooltipEvent) => {
    // add the default path and any
    // potential default properties from a component
    Analytics._track('tooltip', event)
  }, 500),

  track(event: string, properties?: object) {
    Analytics._track(event, properties)
  },

  _page(properties: Record<string, unknown>) {
    const constructedProperties = {
      viewportWidth: window.visualViewport?.width,
      hostname: window.location.hostname,
      environment: env.NEXT_PUBLIC_BACKEND_SOURCE,
      ...properties,
    }

    devLog('Analytics.page', constructedProperties)
    void _analytics.page(constructedProperties)
  },

  _addGaProperties(constructedEvent: any) {
    // NOTE: It's unclear if we still need this or what this is for. This code is > 1 year old.
    // Set default label property to type value for Google Analytics' event.label
    if (constructedEvent && 'type' in constructedEvent && !constructedEvent.label) {
      constructedEvent.label = constructedEvent.type
    }
  },

  _track(event: string, properties?: object) {
    const extraProperties = {
      environment: env.NEXT_PUBLIC_BACKEND_SOURCE,
      path: window.location.pathname,
      hostname: window.location.hostname,
      url: window.location.href,
      viewportWidth: window.visualViewport?.width,
    }

    const constructedEvent = {
      ...extraProperties,
      ...properties,
    }
    devLog(`Analytics.track: ${event}`, constructedEvent)
    Analytics._addGaProperties(constructedEvent)
    void _analytics.track(event, constructedEvent)
  },

  async _identify(traits: UserTraits) {
    return await _analytics.identify(traits)
  },

  mergeProperties<Event extends ClickEvent | CopyEvent>(
    event: Event,
    extraProperties: object
  ): Event {
    if (typeof event === 'string') {
      return {
        type: event,
        ...extraProperties,
      } as Event
    }

    return {
      ...extraProperties,
      ...(event as object),
    } as Event
  },
}
