// cspell:disable
import {type CountryCode} from '~/data/countries'
import {Method, SimpleAPI} from '../../SimpleAPI'

type GeolocationData = {
  city?: string
  country?: CountryCode
  continent?: string
  longitude?: number
  latitude?: number
  isOnboardableCountry: boolean
}

export class Geolocate extends SimpleAPI<GeolocationData> {
  revalidate = 'client-only' as const
  method = Method.GET

  getPath() {
    return '/geolocation'
  }

  protected async getMockResponse() {
    return {
      latitude: 0,
      longitude: 0,
      isOnboardableCountry: true,
      city: 'San Francisco',
      country: 'US' as CountryCode,
      continent: 'North America',
    }
  }
}
