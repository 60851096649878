'use client'

import {getButtonLinkProps} from '~/cms/components/ButtonLinkComponent'
import {SingleLineStructuredText} from '~/cms/components/SingleLineStructuredText'
import {getEmailCaptureDestinationProps} from '~/cms/helpers/getEmailCaptureProps/getEmailCaptureDestinationProps'
import {
  HgSectionTitle,
  type HgSectionTitleContentTypeProps,
} from '~/design-system/hg/modules'
import {type SectionTitleModuleFragment} from './_generated/fragments.graphql'

export type SectionTitleProps = {
  data: SectionTitleModuleFragment
}

function getCTATypeProps(
  data: SectionTitleModuleFragment
): HgSectionTitleContentTypeProps {
  if (data.ctaType === 'email_capture' && data.emailCapture) {
    return {
      contentType: 'emailCapture',
      emailCaptureProps: getEmailCaptureDestinationProps(data.emailCapture),
    }
  }

  return {
    contentType: 'buttonGroup',
    primaryButtonProps: getButtonLinkProps(data.primaryButton),
    secondaryButtonProps: getButtonLinkProps(data.secondaryButton),
  }
}

export function SectionTitle(props: SectionTitleProps) {
  const {title, subhead} = props.data
  return (
    <HgSectionTitle
      title={<SingleLineStructuredText data={title} />}
      subhead={subhead ? <SingleLineStructuredText data={subhead} /> : undefined}
      {...getCTATypeProps(props.data)}
    />
  )
}
