// bare minimum taken from https://github.com/MercuryTechnologies/mercury-web/blob/master/src/utils/Map/index.ts

// suggestion: use M as an import, i.e.:
// import * as M from '~/utils/Map'
import _ from 'lodash'
import * as R from 'ramda'

/**
 * Create a Map from a Record type.
 *
 * NB: if your record has non-string keys, they will be converted to strings in the resulting Map, since Object.keys()
 * always returns a `string[]`.
 */
export function fromRecord<K extends string, V>(obj: Record<K, V>): Map<K, V>
export function fromRecord<K extends number | symbol, V>(
  obj: Record<K, V>
): Map<string, V>
export function fromRecord<K extends string | number | symbol, V>(
  obj: Record<K, V>
): Map<K extends string ? K : string, V> {
  const _entries = new Array<[K extends string ? K : string, V]>()
  _.forIn(obj, (v, k) => _entries.push([k as K extends string ? K : string, v as V]))
  return new Map(_entries)
}

export const entries = <K, V>(m: Map<K, V>): Array<[K, V]> => {
  return Array.from(m.entries())
}

/** Returns an array, where each array item is generated by the given function from the map's keys and values. */
export const mapEntries = <To, K, V>(
  func: (value: V, key: K, index: number) => To,
  m: Map<K, V>
): To[] => {
  return entries(m).map((entry: [K, V], i: number) => func(entry[1], entry[0], i))
}

/** Get an Array of the keys of a given Map */
export const keys = <K>(m: Map<K, any>): K[] => {
  return Array.from(m.keys())
}

/** Get a Set of the keys of a given Map */
export const keySet = <K>(m: Map<K, any>): Set<K> => {
  return new Set(m.keys())
}

/** Returns the first value that the given predicate returns true for it & it's key. */
export const find = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): V | undefined => {
  const found = entries(m).find(([k, v]) => predicate(v, k))
  return found ? found[1] : undefined
}

/** This is how you clone a Map, clone a Map, clone a Map, early in the morning */
export const clone = <K, V>(m: Map<K, V>): Map<K, V> => new Map(m)

/** Get an Array of the values of a given Map */
export const values = <V>(m: Map<any, V>): V[] => {
  return Array.from(m.values())
}

/** Returns true if any of the Map's pairs pass the given predicate. */
export const anyPass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  return find(predicate, m) !== undefined
}

/** Returns true if none of the Map's pairs pass the given predicate. */
export const nonePass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  return !anyPass(predicate, m)
}

/** Returns true if all of the Map's pairs pass the given predicate. */
export const allPass = <K, V>(
  predicate: (value: V, key: K) => boolean,
  m: Map<K, V>
): boolean => {
  // "none of the predicates fail"
  return nonePass(R.compose(R.not, predicate), m)
}
