import {type PropsWithChildren} from 'react'
import {HgThemeSwitcher} from '~/design-system/hg/components'
import {themeToClassName, type Theme} from '~/design-system/hg/tokens/colors'
import {type ThemeSwitcherFragment} from './_generated/fragments.graphql'

type DatoThemeSwitcherProps = {
  applyGlobally?: boolean
  theme: ThemeSwitcherFragment
} & PropsWithChildren

export function DatoThemeSwitcher(props: DatoThemeSwitcherProps) {
  const {children, theme, applyGlobally} = props

  const themeName =
    theme.name in themeToClassName ? (theme.name as Theme) : 'neutral'

  return (
    <HgThemeSwitcher theme={themeName} applyGlobally={applyGlobally}>
      {children}
    </HgThemeSwitcher>
  )
}
