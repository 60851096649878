'use client'
import {Slot} from '@radix-ui/react-slot'
import {useEffect, useRef, useState} from 'react'
import {tv} from 'tailwind-variants'
import {useIsVisible} from '~/design-system/hooks/useIsVisible'

const animationVariants = tv({
  base: 'motion-safe:translate-y-fadeIn motion-safe:opacity-0',
  variants: {
    shouldAnimate: {
      true: 'motion-safe:animate-animateIn',
    },
  },
})

export default function AnimateIn({children}: {children: React.ReactNode}) {
  const animationElRef = useRef<HTMLElement>(null)
  const isVisible = useIsVisible(animationElRef, {
    rootMargin: '0px 0px -35% 0px',
    once: true,
  })

  const [shouldAnimate, setShouldAnimate] = useState(isVisible)

  useEffect(() => {
    if (!animationElRef.current) return
    const observer = new IntersectionObserver(([entry], obs) => {
      // bypass the rootMargin on the useIsVisible intersection observer and determine if the element is visible at all on page load
      if (entry.isIntersecting) {
        // animate in if the element is visible at all
        setShouldAnimate(entry.isIntersecting)
      } else {
        // only check this once and let the useIsVisible hook handle the logic for all other sections
        obs.disconnect()
      }
    })

    observer.observe(animationElRef.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      setShouldAnimate(true)
    }
  }, [isVisible])

  return (
    <Slot ref={animationElRef} className={animationVariants({shouldAnimate})}>
      {children}
    </Slot>
  )
}
