export type SimpleAPIErrorKind =
  // | 'formError'
  // | 'jsonParsing'
  // | 'fileTooLarge'
  // | 'fileUploadFailed'
  // | 'loggedOut'
  // | 'noConnectivity'
  // | 'localError'
  'unknown'

export type SimpleAPIErrorData = {
  statusCode: number
  statusText: string
  rawResponseData: any
}

/**
 * Simplified version of SimpleAPIError from `mercury-web`
 */
export abstract class SimpleAPIError implements SimpleAPIErrorData {
  public abstract readonly kind: SimpleAPIErrorKind
  public readonly statusCode: number
  public readonly statusText: string
  public readonly rawResponseData: any

  constructor(errorData: SimpleAPIErrorData) {
    const {statusCode, statusText, rawResponseData} = errorData
    this.statusCode = statusCode
    this.statusText = statusText
    this.rawResponseData = rawResponseData
  }

  /** All subclasses should be able to produce a (hopefully concise) error message for displaying to the user. */
  public abstract toHumanString(): string
}
