'use client'

import DOMPurify from 'dompurify'
import {ReactSVG} from 'react-svg'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'

export const DatoSvg = ({
  url,
  title,
  className,
}: {
  url: string
  title?: string
  className?: string
}) => {
  const sanitizeSvg = (svg: SVGElement) => {
    DOMPurify.sanitize(svg, {
      IN_PLACE: true,
      USE_PROFILES: {svg: true, svgFilters: true},
    })
  }

  return (
    <ReactSVG
      beforeInjection={sanitizeSvg}
      onError={error => {
        notifyBugsnag('Error injecting svg', {
          caughtError: error,
        })
      }}
      className={className}
      src={url}
      title={title}
      wrapper="span"
    />
  )
}
