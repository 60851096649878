'use client'
import {usePathname} from 'next/navigation'
import {createContext, useContext, useEffect, useState} from 'react'

type NavigationHistoryState = {
  visitedURLs: string[]
  getPreviousURL: () => string | undefined
} | null

const NavigationHistoryContext = createContext<NavigationHistoryState>(null)

export function useNavigationHistory() {
  const context = useContext(NavigationHistoryContext)
  if (!context) {
    throw new Error(
      'useNavigationHistory must be used within a NavigationHistoryProvider'
    )
  }
  return context
}

export default function NavigationHistoryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [visitedURLs, setVisitedURLs] = useState<string[]>([])
  const pathname = usePathname()

  useEffect(() => {
    setVisitedURLs(prev => [...prev, window.location.href])
  }, [pathname])

  function getPreviousURL() {
    if (visitedURLs.length < 2) {
      return
    }
    return visitedURLs[visitedURLs.length - 2]
  }

  return (
    <NavigationHistoryContext.Provider value={{visitedURLs, getPreviousURL}}>
      {children}
    </NavigationHistoryContext.Provider>
  )
}
