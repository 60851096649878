import {Method, SimpleAPI} from '~/mercuryWebBackend/SimpleAPI'
import {sanitizePath} from '~/utils/sanitizePath'
import {type SanitizedPath, type EmptyObject} from '~/utils/types'

export const checkCallsign = (pathPieces: {callsign: string}): SanitizedPath => {
  return sanitizePath(`/check-callsign/${pathPieces.callsign}`)
}
type CheckCallsignPathPieces = {
  callsign: string
}

export type CheckCallsignResponse = {
  available: boolean
}

export default class CheckCallsign extends SimpleAPI<
  CheckCallsignResponse,
  EmptyObject,
  CheckCallsignPathPieces
> {
  method = Method.POST
  // revalidate every 5 minutes
  revalidate = 300
  useInternalMWBUrl = true
  ignoredStatusCodes = [404]

  protected getMockResponse = async ({
    pathPieces,
  }: {
    pathPieces: CheckCallsignPathPieces
  }): Promise<CheckCallsignResponse> => {
    if (pathPieces.callsign === 'fail') {
      return {
        available: false,
      }
    }
    return {
      available: true,
    }
  }

  getPath = checkCallsign
}
