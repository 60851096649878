import * as M from '~/utils/Map'

/**
 * Get a Set of the keys of a given Record object.
 * NB: They will all be strings, even if the record's keys include numbers.
 */
export function recordKeySet<K extends string>(obj: Record<K, any>): Set<K>
export function recordKeySet<K extends number | symbol>(
  obj: Record<K, any>
): Set<string>
export function recordKeySet<K extends string | number | symbol>(
  obj: Record<K, any>
): Set<K extends string ? K : string> {
  const m = M.fromRecord(obj) as Map<K extends string ? K : string, any>
  return M.keySet(m)
}

/**
 * Get an Array of the keys of a given Record object.
 * NB: They will all be strings, even if the record's keys include numbers.
 */
export function recordKeys<K extends string>(obj: Record<K, any>): K[]
export function recordKeys<K extends number | symbol>(obj: Record<K, any>): string[]
export function recordKeys<K extends string | number | symbol>(
  obj: Record<K, any>
): Array<K extends string ? K : string> {
  const m = M.fromRecord(obj) as Map<K extends string ? K : string, any>
  return M.keys(m)
}
