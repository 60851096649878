import {type EmptyObject} from '~/utils/types'
import {Method, SimpleAPI} from '~/mercuryWebBackend/SimpleAPI'

export type NewsletterList =
  | 'ecommerce'
  | 'seriesTea'
  | 'futureOfFintech'
  | 'theBlog'
  | 'raiseCommunity'

export type NewsletterSubscribeParams = {
  email: string
  list: NewsletterList
  source?: string
}

export class NewsletterSubscribe extends SimpleAPI<
  EmptyObject,
  NewsletterSubscribeParams
> {
  revalidate = 'client-only' as const
  method = Method.POST

  getPath() {
    return '/newsletter/subscribe'
  }

  protected async getMockResponse() {
    return {}
  }
}
