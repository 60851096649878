import {type SanitizedPath} from '~/utils/types'
import {Method, SimpleAPI} from '~/mercuryWebBackend/SimpleAPI'
import {sanitizePath} from '~/utils/sanitizePath'
import {type EmptyObject} from 'react-hook-form'
import {capitalize, startCase} from 'lodash'

export type ReferralPartnerAudience = 'startup' | 'ecommerce' | 'investor' | 'web3'

export type SignupReferralMethod = 'PartnerPage' | 'OnboardingAPI'

export type SignupDealKind =
  | '409a'
  | 'aWS5k'
  | 'teaRoom'
  | 'cashBonus'
  | 'creditLimitIncrease'

export type SignupOffer = {
  id: string
  partnerId: string
  kind: SignupDealKind
  cutoffDays?: number
  cashBonusAmount?: number
  debitSpendRequirement?: number
  depositRequirement?: number
  creditSpendRequirement?: number
  referralMethodRequirement?: SignupReferralMethod
  repeatableUntilPayoutCap?: number
  exclusiveWithOtherDeals: boolean
}

type PartnerSignupOffersResponse = {
  name: string
  audience?: ReferralPartnerAudience
  frontendKey: string
  deals: SignupOffer[]
}
type PartnerSignupOffersPathPieces = {
  callsign: string
}

const partnerSignupOffers = (
  pathPieces: PartnerSignupOffersPathPieces
): SanitizedPath => {
  return sanitizePath(`/partner-offers/${pathPieces.callsign}`)
}

export default class PartnerSignupOffers extends SimpleAPI<
  PartnerSignupOffersResponse,
  EmptyObject,
  PartnerSignupOffersPathPieces
> {
  method = Method.GET
  // revalidate every 5 minutes
  revalidate = 300
  useInternalMWBUrl = true
  ignoredStatusCodes = [404]

  protected async getMockResponse({
    pathPieces,
  }: {
    pathPieces: PartnerSignupOffersPathPieces
  }): Promise<PartnerSignupOffersResponse> {
    const pieces = pathPieces.callsign.split('-')

    let deals: SignupOffer[] = []

    if (pieces[0] === 'customer') {
      deals = [
        ...deals,
        {
          id: '1',
          partnerId: '1',
          kind: 'cashBonus',
          cutoffDays: 90,
          cashBonusAmount: 250,
          depositRequirement: 10_000,
          exclusiveWithOtherDeals: false,
        },
      ]
    }

    if (!pieces[1] || pieces[1] === 'debit') {
      deals = [
        ...deals,
        {
          id: '1',
          partnerId: '1',
          kind: 'cashBonus',
          cutoffDays: 90,
          cashBonusAmount: 50,
          debitSpendRequirement: 10_000,
          exclusiveWithOtherDeals: false,
        },
      ]
    }

    if (!pieces[1] || pieces[1] === 'deposit') {
      deals = [
        ...deals,
        {
          id: '1',
          partnerId: '1',
          kind: 'cashBonus',
          cutoffDays: 90,
          cashBonusAmount: 50,
          depositRequirement: 10_000,
          exclusiveWithOtherDeals: false,
        },
      ]
    }

    if (pieces[1] === '409a') {
      deals = [
        ...deals,
        {
          id: '1',
          partnerId: '1',
          kind: '409a',
          exclusiveWithOtherDeals: false,
        },
      ]

      if (pieces.includes('cash')) {
        deals = [
          ...deals,
          {
            id: '1',
            partnerId: '1',
            kind: 'cashBonus',
            cashBonusAmount: 1200,
            exclusiveWithOtherDeals: true,
          },
        ]
      }

      if (pieces.includes('aws')) {
        deals = [
          ...deals,
          {
            id: '1',
            partnerId: '1',
            kind: 'aWS5k',
            exclusiveWithOtherDeals: true,
          },
        ]
      }

      if (pieces.includes('skip')) {
        deals = [
          ...deals,
          {
            id: '1',
            partnerId: '1',
            kind: 'cashBonus',
            cashBonusAmount: 1200,
            debitSpendRequirement: 10000,
            exclusiveWithOtherDeals: true,
          },
        ]
      }
    }

    return {
      name:
        pieces[0] === 'customer' && pieces[1] === 'referral'
          ? startCase(pieces.slice(2).join(' '))
          : capitalize(pieces[0]),
      frontendKey: pathPieces.callsign,
      audience: pieces[0] === 'ecommerce' ? 'ecommerce' : 'startup',
      deals,
    }
  }

  getPath = partnerSignupOffers
}
