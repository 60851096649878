import {SRCImage, type SRCImagePropTypes} from 'react-datocms'
import {
  type FourFiveImageFragment,
  type OneOneImageFragment,
  type SixteenFiveImageFragment,
  type SixteenNineImageFragment,
  type ThreeTwoImageFragment,
  type TwoOneImageFragment,
} from './_generated/fragments.graphql'

export type NoDataSRCImageProps = Omit<SRCImagePropTypes, 'data'>

export type DatoImageProps = {
  data:
    | TwoOneImageFragment
    | OneOneImageFragment
    | ThreeTwoImageFragment
    | FourFiveImageFragment
    | SixteenNineImageFragment
    | SixteenFiveImageFragment
} & NoDataSRCImageProps

export function DatoImage(props: DatoImageProps) {
  const {data, ...restProps} = props

  const image = data.image

  const responsiveImage = image?.responsiveImage
  if (!responsiveImage) return null

  return (
    // eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms
    <SRCImage data={responsiveImage} {...restProps} />
  )
}
