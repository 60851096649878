'use client'
import DisclaimerStructuredText from '~/cms/CMSPage/components/Disclaimers/DisclaimerStructuredText'
import Asset from '~/cms/components/Asset'
import {DatoThemeSelector} from '~/cms/components/DatoThemeSelector'
import {DatoThemeSwitcher} from '~/cms/components/DatoThemeSwitcher'
import {SingleLineStructuredText} from '~/cms/components/SingleLineStructuredText'
import {HgHero, type HgHeroBaseProps} from '~/design-system/hg/modules'
import {safeUnreachableCase} from '~/utils/unreachableCase'
import {type HeroSectionProps} from './_types'
import {getCTATypeProps, getLogo, structuredTextCharacterCount} from './_utils'
import {SRCImage} from 'react-datocms'

export default function HeroSection({data}: HeroSectionProps) {
  const {variant, disclaimer, title, subhead, theme, alignment, breadcrumbs} = data

  const defaultProps: HgHeroBaseProps = {
    ...getCTATypeProps(data),
    headline: <SingleLineStructuredText data={title} />,
    subhead: <SingleLineStructuredText data={subhead} />,
    disclaimer: disclaimer && <DisclaimerStructuredText data={disclaimer.text} />,
    alignment: alignment as HgHeroBaseProps['alignment'],
    breadcrumbs: breadcrumbs?.map(({link, text}) => ({
      link,
      label: <SingleLineStructuredText data={text} />,
    })),
  }

  if (!variant) {
    return (
      <DatoThemeSwitcher theme={theme}>
        <section data-section="hero">
          <HgHero {...defaultProps} type="headingOnly" />
        </section>
      </DatoThemeSwitcher>
    )
  }

  switch (variant.__typename) {
    case 'CinematicHeroComponentRecord': {
      return (
        <DatoThemeSwitcher theme={theme} applyGlobally={false}>
          <div>
            <DatoThemeSelector theme={theme}>
              <section data-section="hero">
                <HgHero
                  type="cinematic"
                  // TODO: improve implementation of aspect ratio across screens
                  backgroundImage={
                    <>
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden 3xl:block"
                        imgClassName="object-cover h-full"
                        data={
                          variant.heroSixteenNineBackgroundImage.image
                            .responsiveImage
                        }
                        priority
                      />
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden lg:block 3xl:hidden"
                        imgClassName="object-cover h-full"
                        data={
                          variant.heroThreeTwoBackgroundImage.image.responsiveImage
                        }
                        priority
                      />
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden sm:block lg:hidden"
                        imgClassName="object-cover h-full"
                        data={
                          variant.heroFourFiveBackgroundImage.image.responsiveImage
                        }
                        priority
                      />
                    </>
                  }
                  maskImage={
                    <>
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden 3xl:block"
                        imgClassName="object-cover h-full"
                        data={variant.heroSixteenNineMaskImage.image.responsiveImage}
                        priority
                      />
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden lg:block 3xl:hidden"
                        imgClassName="object-cover h-full"
                        data={variant.heroThreeTwoMaskImage.image.responsiveImage}
                        priority
                      />
                      {/* eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms */}
                      <SRCImage
                        pictureClassName="hidden sm:block lg:hidden"
                        imgClassName="object-cover h-full"
                        data={variant.heroFourFiveMaskImage.image.responsiveImage}
                        priority
                      />
                    </>
                  }
                  mobileMaskImage={
                    // eslint-disable-next-line jsx-a11y/alt-text -- alt/title is handled by datocms
                    <SRCImage
                      imgClassName="object-cover h-full"
                      data={variant.mobileMaskImage.image.responsiveImage}
                      priority
                    />
                  }
                  {...defaultProps}
                />
              </section>
            </DatoThemeSelector>
          </div>
        </DatoThemeSwitcher>
      )
    }
    case 'DefaultHeroComponentRecord':
      return (
        <DatoThemeSwitcher theme={theme}>
          <section data-section="hero">
            <HgHero
              {...defaultProps}
              type="default"
              assetTwoOneRatio={
                <Asset
                  data={variant.defaultHeroModuleTwoOneAsset}
                  aspectRatio="2:1"
                />
              }
              assetFourFiveRatio={
                <Asset
                  data={variant.defaultHeroModuleFourFiveAsset}
                  aspectRatio="4:5"
                />
              }
            />
          </section>
        </DatoThemeSwitcher>
      )
    case 'PartnerPageHeroSection': {
      return (
        <DatoThemeSwitcher theme={theme}>
          <section data-section="hero">
            <HgHero
              {...defaultProps}
              type="splitImage"
              size={
                !subhead || structuredTextCharacterCount(subhead.text) < 200
                  ? 'short'
                  : 'long'
              }
              logoAsset={getLogo(data.logo)}
              assetFourFiveRatio={
                <Asset data={variant.fourFiveAsset} aspectRatio="4:5" />
              }
            />
          </section>
        </DatoThemeSwitcher>
      )
    }
    default:
      return safeUnreachableCase(variant, null)
  }
}
