// These variables can be shared between CSS and Javascript
// Any edits to this file should be mirrored in `.storybook/shared-variables.js`, see the comment there for why.

// Base Colors
const baseColors = {
  '--ds-grey': '#71718E',
  '--ds-grey-medium': '#565674',
  '--ds-grey-dark': '#41415A',
  '--ds-black': '#1F1F30',
  '--ds-white': '#FFFFFF',
  '--ds-white-transparent': '#FFFFFFCC',
  '--ds-off-white': '#F9F9FB',
  '--ds-off-white-dark': '#F2F2F7',
  '--ds-pearl-grey': '#EFEFFD',
  '--ds-pearl-grey-medium': '#E4E5FC',
  '--ds-pearl-grey-dark': '#D8D8E9',
  '--ds-pearl-grey-darkest': '#9D9DB0',
  '--ds-blue-light': '#607AFF',
  '--ds-blue': '#4D68EB',
  '--ds-blue-dark': '#3B55D6',
  '--ds-green-light': '#DFF7F4',
  '--ds-green': '#0AD19F',
  '--ds-green-dark': '#008375',
  '--ds-red-light': '#FAE5F1',
  '--ds-red-medium': '#EAB0CF',
  '--ds-red': '#D9177E',
  '--ds-red-dark': '#C21877',
  '--ds-highlight-light': '#EAF4FE',
  '--ds-highlight': '#CAE2FC',
  '--ds-highlight-dark': '#AAD0F8',
  '--ds-admin-light': '#C5EBFD',
  '--ds-admin-dark': '#355B7F',
  '--ds-custom-light': '#D1CEFB',
  '--ds-custom-dark': '#494579',
  '--ds-bookkeeper-light': '#C0D3F3',
  '--ds-bookkeeper-dark': '#254174',
  '--ds-card-only-light': '#C8F3EA',
  '--ds-card-only-dark': '#2D5E74',
  '--ds-mauve-light': '#D9BEFF',
  '--ds-mauve-dark': '#BD94F5',
  '--ds-deep-blue-light': '#2C45C2',
  '--ds-deep-blue-dark': '#1F37AD',
}
// Gradients
const inputGradients = {
  ...baseColors,
  '--ds-gradient-background-input': `linear-gradient(
    360deg,
    ${baseColors['--ds-pearl-grey']}1A 0%,
    ${baseColors['--ds-pearl-grey']}33 100%),
    ${baseColors['--ds-white']};`,
  '--ds-gradient-background-input-hovered': `linear-gradient(
    360deg,
    ${baseColors['--ds-pearl-grey']}0D 0%,
    ${baseColors['--ds-pearl-grey']}1A 100%),
    ${baseColors['--ds-white']};`,
}

const dataVisualizationGradients = {
  ...baseColors,
  '--ds-gradient-data-visualization-area-primary': `linear-gradient(180deg, ${baseColors['--ds-pearl-grey']} 0%, #efeffd1a 100%);`,
  '--ds-gradient-data-visualization-thermometer-secondary': `linear-gradient(${baseColors['--ds-white']} 50%, ${baseColors['--ds-blue-light']} 100%);`,
  '--ds-gradient-data-visualization-thermometer-background': `linear-gradient(${baseColors['--ds-pearl-grey-darkest']} 15%, ${baseColors['--ds-off-white-dark']} 100%);`,
}

const gradients = {
  ...inputGradients,
  ...dataVisualizationGradients,
  '--ds-gradient-overflow-fade-mask':
    'linear-gradient(to left, transparent 0%, black 48px, black 100%);',
}

// Semantic Colors
const backgroundColors = {
  ...baseColors,
  '--ds-background-default': baseColors['--ds-white'],
  '--ds-background-default-hovered': baseColors['--ds-off-white'],
  '--ds-background-primary': baseColors['--ds-blue'],
  '--ds-background-primary-hovered': baseColors['--ds-blue-dark'],
  '--ds-background-secondary': baseColors['--ds-off-white'],
  '--ds-background-secondary-hovered': baseColors['--ds-off-white-dark'],
  '--ds-background-secondary-high-contrast': baseColors['--ds-off-white-dark'],
  '--ds-background-emphasized': baseColors['--ds-pearl-grey'],
  '--ds-background-emphasized-hovered': baseColors['--ds-pearl-grey-medium'],
  '--ds-background-destructive': baseColors['--ds-off-white'],
  '--ds-background-destructive-hovered': baseColors['--ds-red-light'],
  '--ds-background-inverted': baseColors['--ds-black'],
  '--ds-background-success': baseColors['--ds-green-light'],
  '--ds-background-error': baseColors['--ds-red-light'],
  '--ds-background-foyer-glass': baseColors['--ds-white-transparent'],
  '--ds-background-input-focused': baseColors['--ds-white'],
  '--ds-background-input-disabled': baseColors['--ds-off-white'],
  '--ds-background-input-checked': baseColors['--ds-blue'],
  '--ds-background-input-checked-hovered': baseColors['--ds-blue'],
  '--ds-background-input-checked-disabled': baseColors['--ds-pearl-grey-darkest'],
  '--ds-background-role-admin': baseColors['--ds-admin-light'],
  '--ds-background-role-bookkeeper': baseColors['--ds-bookkeeper-light'],
  '--ds-background-role-card-only': baseColors['--ds-card-only-light'],
  '--ds-background-role-custom': baseColors['--ds-custom-light'],
}

const textColors = {
  ...baseColors,
  '--ds-text-default': baseColors['--ds-grey-dark'],
  '--ds-text-secondary': baseColors['--ds-grey-medium'],
  '--ds-text-tertiary': baseColors['--ds-grey'],
  '--ds-text-emphasized': baseColors['--ds-black'],
  '--ds-text-disabled': baseColors['--ds-pearl-grey-darkest'],
  '--ds-text-placeholder': baseColors['--ds-pearl-grey-darkest'],
  '--ds-text-focused': baseColors['--ds-blue'],
  '--ds-text-title': baseColors['--ds-black'],
  '--ds-text-error': baseColors['--ds-red-dark'],
  '--ds-text-success': baseColors['--ds-green-dark'],
  '--ds-text-link': baseColors['--ds-blue'],
  '--ds-text-link-hovered': baseColors['--ds-blue'],
  '--ds-text-money': baseColors['--ds-grey-dark'],
  '--ds-text-money-pending-or-null': baseColors['--ds-grey'],
  '--ds-text-money-out': baseColors['--ds-red-dark'],
  '--ds-text-money-in': baseColors['--ds-green-dark'],
  '--ds-text-on-primary': baseColors['--ds-white'],
  '--ds-text-on-secondary': baseColors['--ds-grey-dark'],
  '--ds-text-on-secondary-hovered': baseColors['--ds-black'],
  '--ds-text-on-inverted': baseColors['--ds-white'],
  '--ds-text-on-inverted-secondary': baseColors['--ds-pearl-grey-dark'],
  '--ds-text-on-emphasized': baseColors['--ds-grey-dark'],
  '--ds-text-on-emphasized-hovered': baseColors['--ds-grey-dark'],
  '--ds-text-role-admin': baseColors['--ds-admin-dark'],
  '--ds-text-role-bookkeeper': baseColors['--ds-bookkeeper-dark'],
  '--ds-text-role-card-only': baseColors['--ds-card-only-dark'],
  '--ds-text-role-custom': baseColors['--ds-custom-dark'],
}

const iconColors = {
  ...baseColors,
  '--ds-icon-default': baseColors['--ds-grey-dark'],
  '--ds-icon-primary': baseColors['--ds-blue'],
  '--ds-icon-primary-hovered': baseColors['--ds-blue-dark'],
  '--ds-icon-secondary': baseColors['--ds-grey'],
  '--ds-icon-tertiary': baseColors['--ds-pearl-grey-darkest'],
  '--ds-icon-emphasized': baseColors['--ds-black'],
  '--ds-icon-success': baseColors['--ds-green-dark'],
  '--ds-icon-error': baseColors['--ds-red-dark'],
  '--ds-icon-money-in': baseColors['--ds-green-dark'],
  '--ds-icon-money-out': baseColors['--ds-red-dark'],
  '--ds-icon-on-primary': baseColors['--ds-white'],
  '--ds-icon-on-secondary': baseColors['--ds-grey-dark'],
  '--ds-icon-on-secondary-hovered': baseColors['--ds-blue'],
  '--ds-icon-on-tertiary': baseColors['--ds-pearl-grey-darkest'],
  '--ds-icon-on-tertiary-hovered': baseColors['--ds-blue'],
  '--ds-icon-on-inverted': baseColors['--ds-white'],
  '--ds-icon-on-inverted-success': baseColors['--ds-green'],
  '--ds-icon-on-inverted-error': baseColors['--ds-red'],
}

const borderColors = {
  ...baseColors,
  '--ds-border-default': baseColors['--ds-pearl-grey'],
  '--ds-border-emphasized': baseColors['--ds-pearl-grey-medium'],
  '--ds-border-error': baseColors['--ds-red-medium'],
  '--ds-border-on-inverted': baseColors['--ds-grey-dark'],
  '--ds-border-input': baseColors['--ds-pearl-grey-medium'],
  '--ds-border-input-emphasized': baseColors['--ds-pearl-grey-dark'],
  '--ds-border-input-focused': baseColors['--ds-pearl-grey'],
  '--ds-border-input-error': baseColors['--ds-red-medium'],
}

const dataVisualizationColors = {
  ...baseColors,
  '--ds-data-visualization-line': baseColors['--ds-grey'],
  '--ds-data-visualization-line-primary': baseColors['--ds-blue'],
  '--ds-data-visualization-line-marker': baseColors['--ds-pearl-grey-darkest'],
  '--ds-data-visualization-axis-line': baseColors['--ds-grey-medium'],
  '--ds-data-visualization-tick-line': baseColors['--ds-pearl-grey'],
  '--ds-data-visualization-tick-line-minor': baseColors['--ds-off-white-dark'],
  '--ds-data-visualization-thermometer-primary': baseColors['--ds-blue'],
}

const semanticColors = {
  ...backgroundColors,
  ...textColors,
  ...iconColors,
  ...borderColors,
  ...dataVisualizationColors,
}

const borderRadii = {
  '--ds-border-radius-small': '4px',
  '--ds-border-radius-medium': '8px',
  '--ds-border-radius-large': '12px',
  '--ds-border-radius-round': '100%',
}

// FEM Colors
const femColors = {
  '--fem-lapis-900': '#040C34',
  '--fem-lapis-800': '#242A49',
  '--fem-lapis-700': '#343A5B',
  '--fem-lapis-600': '#4D5373',
  '--fem-lapis-500': '#676D8E',

  '--fem-marcel-900': '#1F212E',
  '--fem-marcel-800': '#2F3246',
  '--fem-marcel-700': '#393D56',
  '--fem-marcel-600': '#4E5374',
  '--fem-marcel-500': '#8287A6',
  '--fem-marcel-400': '#B3B8D6',
  '--fem-marcel-300': '#D3D6E9',
  '--fem-marcel-200': '#E7E9F4',
  '--fem-marcel-100': '#F3F4FC',
  '--fem-marcel-50': '#F9FAFF',
  '--fem-marcel-0': '#FCFDFF',

  '--fem-slate-700': '#383E56',
  '--fem-slate-500': '#616883',

  '--fem-mercury-700': '#1835C1',
  '--fem-mercury-600': '#465ED1',
  '--fem-mercury-500': '#395AFF',
}

const femSemanticColors = {
  ...femColors,
  '--fem-primary': femColors['--fem-mercury-500'],
  '--fem-black': femColors['--fem-lapis-900'],
}

const customProperties = {
  ...baseColors,
  ...semanticColors,
  ...femColors,
  ...femSemanticColors,
  ...gradients,
  ...borderRadii,
  '--demo-banner-height': '56px',
}

/**
 * Our common device-size breakpoints are as follows:
 *
 * "Desktop" - The largest device size. [1100px, ∞) wide.
 * "Tablet" - Larger than mobile but smaller than desktop. [768px, 1100px) wide.
 * "Mobile landscape" - Smaller than tablets, wider than the thinnest phones. [540px, 768px) wide.
 * "Mobile" - Smallest device size. (0*, 540px) wide.
 *
 * (*In practice, we only bother testing down to 320px as the smallest supported width)
 *
 * These aren't the only breakpoints you are allowed to use, but they are the ones you'll find most designs
 * try and represent.
 */
const customMedia = {
  '--fem-mobile-and-wider': '(min-width: 360px)',
  '--mobile-landscape-and-wider': '(min-width: 540px)',
  '--tablet-and-wider': '(min-width: 768px)',
  '--desktop-only': '(min-width: 1100px)',
  '--large-desktop-and-wider': '(min-width: 1440px)',

  // The reasoning for .98 is described here: https://getbootstrap.com/docs/5.0/layout/breakpoints/#max-width
  '--tablet-and-thinner': '(max-width: 1099.98px)',
  '--mobile-landscape-and-thinner': '(max-width: 767.98px)',
  '--fem-mobile-landscape-and-thinner': '(max-width: 639.98px)',
  '--mobile-only': '(max-width: 539.98px)',
  '--fem-mobile-only': '(max-width: 359.98px)',

  '--template-fem-max-supported-desktop-width': '(min-width: 1728px)',
  '--template-fem-desktop-and-thinner': '(max-width: 1599.98px)',
  '--template-fem-tablet-and-thinner': '(max-width: 1023.98px)',
  '--template-fem-mobile-only': '(max-width: 599.98px)',
}

module.exports = {
  customProperties,
  customMedia,
  baseColors,
  semanticColors,
  femColors,
  femSemanticColors,
  gradients,
  borderRadii,
}
