'use client'
import DOMPurify from 'dompurify'
import Image from 'next/image'
import {ReactSVG} from 'react-svg'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {type LogoFragment} from './_generated/query.graphql'

const LogoSVG = ({logo}: {logo: LogoFragment['logo']}) => {
  return (
    <ReactSVG
      beforeInjection={svg => {
        // DOMPurify eagerly removes elements with xlink:href attributes, which are commonly used by <use> tags in SVGs.
        // It should be safe to allow them if they are hash links
        // https://github.com/cure53/DOMPurify/issues/233
        DOMPurify.addHook('afterSanitizeAttributes', function (node) {
          if (
            node.hasAttribute('xlink:href') &&
            node.tagName === 'use' &&
            !node.getAttribute('xlink:href')?.match(/^#/)
          ) {
            node.remove()
          }
        })

        DOMPurify.sanitize(svg, {
          IN_PLACE: true,
          USE_PROFILES: {svg: true, svgFilters: true},
          ADD_TAGS: ['use'],
        })
      }}
      onError={error => {
        notifyBugsnag('Error injecting LogoMash svg', {
          caughtError: error,
        })
      }}
      src={logo.logo.url}
      title={logo.companyName}
      fallback={() => (
        <Image
          src={logo.logo.url}
          alt={logo.companyName}
          width={logo.logo.width}
          height={logo.logo.height}
          className="h-full w-auto"
        />
      )}
      wrapper="span"
    />
  )
}

export default LogoSVG
