'use client'
import DOMPurify from 'dompurify'
import {ReactSVG} from 'react-svg'
import {graphic} from '~/design-system/hg/modules/HgProductSummary/ProductSummaryGraphic'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'
import {type ProductOfferingFragment} from './_generated/query.graphql'

const ProductOfferingItem = ({
  productOfferingTextSvg,
  title,
}: {
  productOfferingTextSvg: ProductOfferingFragment['productOfferingTextSvg']
  title: string
}) => {
  const {productDescription} = graphic()

  return (
    <ReactSVG
      beforeInjection={svg => {
        DOMPurify.sanitize(svg, {
          IN_PLACE: true,
          USE_PROFILES: {svg: true, svgFilters: true},
        })
      }}
      onError={error => {
        notifyBugsnag('Error injecting Product Summary svg', {
          caughtError: error,
        })
      }}
      className={productDescription()}
      src={productOfferingTextSvg.url}
      title={title}
      wrapper="svg"
    />
  )
}

export default ProductOfferingItem
