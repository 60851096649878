import {isNil} from 'ramda'
import {safely, safelyOr} from './safely'
import {type SanitizedPath} from './types'

/**
 * Filters a string for use as a URL path. Double-url-encodes the dots in
 * '/.' and '/..', to prevent the browser from evaluating them as directory traversals.
 *
 * Motivation: given a path template like https://api.com/things/${thingId}/action,
 * using sneaky user-provided `thingId` values like `../other-action?ignore=` produces
 * a URL like https://api.com/things/../other-action?ignore=/action,
 * which the browser then evaluates to https://api.com/other-action?ignore=/action,
 * causing a call to the `/other-action` endpoint instead of the desired `/action` path.
 *
 * This can be abused to construct CSRF-like poisoned links, which, when clicked by a user,
 * would cause an unexpected call to an arbitrary MWB path.
 */

// eslint-disable-next-line import/no-unused-modules
export function sanitizePath(path: string): SanitizedPath {
  // '/..' does not matter in hash (#x) or querystring (?k=v)
  const endOfPathPart = safely(path.match(/[?#]/), matches => matches.index)
  const pathPart = isNil(endOfPathPart) ? path : path.substring(0, endOfPathPart)
  const filteredPath = pathPart.replace(
    // note: browsers generally treat \ equivalently to / in paths
    /([/\\])(\.|%2e)(\.|%2e)?(?=([/\\]|$))/gi,
    (_fullMatch: string, _slash: string, _dot1: string, dot2: string) => {
      const doublePercentEncodedDot = '%252E'
      return `/${doublePercentEncodedDot}${dot2 ? doublePercentEncodedDot : ''}`
    }
  )
  // This is the one place in the codebase that a SanitizedPath should be constructed,
  // because we've just validated its preconditions.
  return (filteredPath +
    safelyOr(endOfPathPart, idx => path.substring(idx), '')) as SanitizedPath
}
