import {SimpleAPIError, type SimpleAPIErrorData} from './SimpleAPIError'

export class SimpleAPIUnknownError extends SimpleAPIError {
  kind = 'unknown' as const
  public readonly contextualMessage: string

  constructor(errorData: SimpleAPIErrorData & {contextualMessage: string}) {
    super(errorData)
    this.contextualMessage = errorData.contextualMessage
  }

  public toHumanString(): string {
    return this.contextualMessage
  }
}
