import {type EmptyObject} from 'react-hook-form'
import {Method, SimpleAPI} from '../../SimpleAPI'
import {type CountryCode} from '~/data/countries'

type ContactTreasuryParams = {
  name: string
  email: string
  country: CountryCode
  website: string
  investors?: string
  plannedCents: number
  comments?: string
}

export class ContactTreasury extends SimpleAPI<EmptyObject, ContactTreasuryParams> {
  revalidate = 'client-only' as const
  method = Method.POST

  getPath() {
    return '/contact/treasury'
  }

  protected async getMockResponse() {
    return {}
  }
}
